
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class InventoryList extends Vue {
  // 仓库
  wareHouseList = []
  // 物料分类
  materialList = []

  private info = {
    projectId: '',
    // 仓库
    warehouseId: '',
    // 物料
    materialTypeId: '',
    material: ''
  }

  props = {
    value: 'materialTypeId',
    label: 'materialTypeName',
    children: 'childNode'
  }

  private tableData = {
    loading: false,
    data: []
  }

  page = 1
  size = 10
  total = 0

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getMaterialList()
    this.getData()
  }

  // 仓库
  getWareHouseList () {
    this.info.warehouseId = ''
    this.$axios.get(this.$apis.material.selectWarehouseByList, { projectId: this.info.projectId }).then(res => {
      this.wareHouseList = res.list || []
    })
  }

  // 物料
  getMaterialList () {
    this.$axios.get(this.$apis.material.selectMaterialTypeByList).then(res => {
      this.materialList = res || []
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.material.selectStockByList, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
